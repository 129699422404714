.container {
  max-width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}

.container h6,
.container h5 {
  font-size: 16px;
  font-weight: 400;
}

.container .container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .container_body {
  display: grid;
  grid-template-columns: 1fr; /* Mobile */
  gap: 1.5rem;
  margin: 1rem 0;
}

.container .container_body_template {
  border-radius: 10px;
  background-color: #fff;
  padding: 1rem;
}

.container .container_body_template .container_body_template_header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.container .container_body_template .container_body_template_header h6 {
  font-size: 20px;
}

.container .container_body_template .container_body_template_body {
  border-radius: 10px;
  background-color: #ebf0f4;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 150px;
  max-height: 150px;
  overflow-y: scroll;
}

.container .container_body_template .container_body_template_footer {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
}

.container .container_footer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

@media (min-width: 700px) {
  .container .container_body {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 976px) {
  .container .container_body {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.empty_container {
  height: 75vh;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px; /* Adds padding to avoid overflow */
  box-sizing: border-box;
}

.empty_container h5 {
  color: #000;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}
.scroll-top-wrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}


.main {
  position: relative;
}