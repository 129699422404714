.bulk_container {
  display: flex;
  background-color: #fff;
  padding: 8px 10px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;

  .button_group {
    display: flex;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ebf0f4;
    padding: 8px 0px;

    button {
      background-color: transparent;
      padding: 0 10px;
      border-right: 1.2px solid #0000004d;
      min-width: 100px;
      font-family: "Gilroy-Semibold";
      font-size: 13px;
      line-height: 14px;
      color: #000;

      &:last-child {
        border-right: 0px;
      }
    }
  }

  span {
    margin-left: 10px;
    font-size: 15px;
    line-height: 16px;
    font-family: "Gilroy-Semibold";
    color: #000;
    align-self: center;
  }
  .reset_button {
    background-color: #ebf0f4;
    border-radius: 6px;
    padding: 6px 8px;
    margin-left: auto;
    font-size: 14px;
    line-height: 16px;
    font-family: "Gilroy-Semibold";
    color: #000;

    svg {
      margin-right: 5px;
    }
  }
}
