body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ebf0f4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
.apexcharts-tooltip *,
.apexcharts-text tspan,
.funnel-pipeline-chart-row,
.apexcharts-legend-text,
.theme-fm {
  font-family: "Gilroy-Bold" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
}
.fm-gilroy {
  font-family: "Gilroy-Medium" !important;
}
.fm-gilroyBold {
  font-family: "Gilroy-Bold" !important;
}
.fm-gilroyHeavy {
  font-family: "Gilroy-Heavy" !important;
}
/* New css */

.funnel-pipeline-chart {
  /* clip-path: polygon(0% 0%, 100% 0%, 65% 100%, 4% 112%, 61% 100%, 30% 100%); */
  text-align: center;
  clip-path: polygon(0% 0%, 50% 138%, 100% 0%)!important;
}

.funnel-pipeline-chart .funnel-pipeline-chart-row:last-child {
  padding-bottom: 10px!important;
}
.funnel-pipeline-chart .funnel-pipeline-chart-row{
  margin: 10px 0px!important;
}
.flextabletd img {
  width: 15px;
}

.form_tab {
  background: #fff;
  margin-right: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 10px 20px;
  color: #000;
}
.dialog_box_model .rs-modal-content {
  background-color: #ebf0f4;
}
.dialog_box_model .top_bar {
  display: flex;
  justify-content: space-between;
}
.top_bar label {
  font-size: 26px;
}
.dialog_box_model .top_bar img {
  width: 16px;
  height: 16px;
}

.question_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.action_button {
  border-radius: 6px;
  background-color: #ebf0f4;
  margin: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 1s;
  font-weight: 800;
  width: 100%;
}
.action_button:hover {
  background-color: #08060633;
}
.action_button img {
  background: #fff;
  padding: 8px;
  border-radius: 3px;
  width: 34px;
  height: 34px;
  object-fit: contain;
}
.content_box {
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  max-height: 60vh;
  min-height: 60vh;
  overflow: scroll;
}

.content_box button {
  border-radius: 6px;
  background-color: #ebf0f4;
  margin: 10px 0px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 1s;
  font-weight: 800;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content_box button p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content_box button:hover,
.content_box button.active {
  background-color: #1a1a1a21;
}
.content_box button.active {
  background-color: #1a1a1a21;
}
.content_box button span {
  background-color: #fff;
  border-radius: 4px;
  padding: 0px 6px;
}
.condition_box {
  margin-top: 20px;
}
.condition_box input {
  width: 58px;
  border-radius: 4px;
  height: 24px;
  margin-right: 4px;
  border: 1px solid #6e788a;
}
.condition_box select {
  border: 1px solid #6e788a;
  border-radius: 4px;
  padding: 2px;
}
.condition_box button {
  background: none;
  width: fit-content;
}
.condition_box button:hover {
  color: #3faefd;
  background: none;
}
.condition_box .condition_title_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.conditions {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}

select.select_lead_list {
  width: 100%;
  border: 1px solid #ced4da;
  padding: 9px;
  border-radius: 4px;
}
select.select_lead_list:focus {
  outline: none;
  border: 1px solid #0000ff;
}
.form_label {
  margin: 8px 0px 4px 0px;
}
.link_icon {
  padding: 10px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.share_lead_box {
  .action_btn {
    background: #fff;
    padding: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
  .share_card {
    border: 1px solid #ced4da;
    margin: 14px 0px;
    border-radius: 10px;
    overflow: hidden;
  }
  .share_content {
    padding: 10px;
    background-color: #ffffff57;
  }
  .share_buttons {
    margin-top: 10px;
    > button {
      display: block;
      background: #fff;
      padding: 8px;
      width: 100%;
      margin: 10px 0px;
      color: #1675e0;
      text-align: left;
    }
  }
}
@media (max-width: 768px) {
  .question_box {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .content_box {
    min-height: auto;
  }
  .question_box {
    grid-template-columns: 1fr 1fr;
  }
  .question_list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    button {
      width: fit-content;
    }
  }
}

.avatar-md {
  height: 35px;
  width: 35px;
}

.object-fit-cover {
  object-fit: cover;
}
.object-fit-contain {
  object-fit: contain;
}

.vertical-middle tr td {
  vertical-align: middle;
}
.rounded-15 {
  border-radius: 15px;
}

.custom-table > thead > tr > th {
  border-bottom: none;
  font-size: 16px;
}
.custom-table > tbody > tr > td {
  border-bottom: none;
}
.custom-table > tbody > tr > td:first-child {
  border-radius: 15px 0 0 15px;
}
.custom-table > tbody > tr > td:last-child {
  border-radius: 0 15px 15px 0;
}
.custom-table > tbody > tr > td {
  background-color: #ebf0f480;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}
.bg-light {
  background-color: #ebf0f480 !important;
}

.crowntext {
  color: #ffd700;
  font-weight: 900;
  font-size: 20px;
}
.crowntext img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}
.flextabletd {
  display: flex;
  align-items: center;
}
.border-right-dashed  {
  
  border-right-style: dashed!important;
}
.border-bottom-dashed{
  border-bottom-style: dashed!important;
}
.font-smalls {
  font-size: 14px;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  padding: 8px;
  background: #fff;
  cursor: pointer;
  height: 35px;
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 5px;
  border-radius: 15px;
  border: 0;
  font-family: "Gilroy-Bold";
}



.dropdown-list {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.header_flex label.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  justify-content: space-between;
  font-size: 15px;
  font-family: "Gilroy-Bold";
}
