/* src/components/CallLogItem.module.css */

.listItem {
  background: #ebf0f4;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.listItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.avatarContainer {
  position: relative;
  display: inline-block;
}

.avatar {
  border-radius: 50%;
}

.callTypeIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  border-radius: 50%;
  padding: 2px;
}

.titleContainer {
  display: flex;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
}

.callName {
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
}

.concurrentTag {
  margin-left: 8px;
  font-family: 'Gilroy', sans-serif;
}

.descriptionLine {
  display: flex;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
}

.phoneNumber {
  font-size: 14px;
  color: #555;
}

.callTime {
  font-size: 14px;
  color: #555;
  margin-right: 8px;
}

.callTypeTag {
  font-size: 12px;
}

.durationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: 'Gilroy', sans-serif;
}

.duration {
  font-size: 14px;
  color: #555;
}

.recordingLink {
  font-size: 12px;
  color: #1890ff;
  margin-top: 4px;
  text-decoration: none;
}

.recordingLink:hover {
  text-decoration: underline;
}
