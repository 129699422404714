/* src/components/CallLogsDrawer.css */

.search-container {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  .search-input {
    flex: 1;
  }
  
  .refresh-button {
    flex: none; /* Prevents it from expanding */
    display: flex;
    align-items: center;
    padding: 6px 10px; /* Compact padding */
    font-size: 12px; /* Smaller font size */
    color: #007bff;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    cursor: pointer;
    transition: color 0.2s, border-color 0.2s;
}

.refresh-button:hover {
    color: #0056b3; /* Darker blue on hover */
    border-color: #0066FF;
}

.refresh-button:focus {
    outline: none;
}

  .loader,
  .end-message,
  .empty-state {
    text-align: center;
    padding: 16px 0;
  }
  
  .call-logs-list {
    /* Your list styles */
  }
  
  .empty-state h4 {
    margin-bottom: 8px;
    color: #ff4d4f;
  }
  
  .empty-state p {
    color: #595959;
  }
  