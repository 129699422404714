h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Gilroy-Medium";
}
.inboxPage {
  padding-top: 20px !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; 
}

.disable_overflow {
  overflow-y: hidden !important;
}

.userLists {
  width: 460px !important;  /* Increased width */
  min-width: 460px !important;
  max-width: 460px !important;
  padding: 0 4px;  /* Reduce padding */
  display: flex;
  flex-direction: column;
  background: transparent;
}



.chat-list-wrapper {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important;
}

.userListItems {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.userLists,.rightSideBox {
  width: 260px;
  max-width: 2600px;
  padding-left: 4px;
  padding-right: 4px;
}
.rightSideBox {
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  padding: 0;  /* Reduce padding */
}


.userLists .filter {
  display: flex;
  margin-bottom: 10px;
}

.userLists .filter .search {
  position: relative;
  flex: 1;
}

.userLists .filter .search .searchGlass {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #00000080;
  font-size: 12px;
}

.userLists .filter .search input {
  width: 100%;
  background: #fff;
  font-family: "Gilroy-Semibold";
  color: #00000080;
  border: 0;
  border-radius: 10px;
  height: 30px;
  padding: 10px;
  font-size: 11px;
  padding-left: 30px;
}

.userLists .filter .search input:focus {
  outline: none;
}

.userLists .filter .addBtn {
  background: #fff;
  border-radius: 10px;
  width: 35px;
  height: 30px;
  border: 0;
  margin-left: 10px;
}

.userLists .filterChips {
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  flex-flow: wrap;
}

.userLists .filterChips button {
  background: #fff;
  border-radius: 8px;
  font-size: 10px;
  color: #000;
  border: 0;
  font-family: "Gilroy-Bold";
  font-weight: 500;
  padding: 4px 8px;
  font-size: 11px;
}
.userLists .filterChips button.active {
  color: white;
  background-color: #ebf0f4;
}


.userLists ul {
  padding-left: 0;
}

.userLists ul li {
  list-style: none;
}

.userLists ul li:not(:first-of-type) {
  margin-top: 10px;
}

.userLists ul li .item {
  background: #fff;
  /* border-radius: 6px; */
  display: flex;
  align-items: flex-start;
  padding: 8px 4px;
  cursor: pointer;
  width: 100% !important;
}

.userLists ul li .item img {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-right: 10px;
}

.userLists ul li .item .mob {
  flex-grow: 1;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  font-family: "Gilroy-Bold";
}

.userLists ul li .item .time {
  color: rgb(0 0 0 / 50%);
  font-family: "Gilroy-Bold";
  font-size: 10px;
  color: #4cb050;
}

.userLists ul li .item p {
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  color: rgb(0 0 0 / 50%);
  font-size: 12px;
  font-weight: 600;
  font-family: "Gilroy-Medium";
}

.ellipsisTxt {
  flex-grow: 1;
  overflow: hidden;
  color: rgb(0 0 0 / 50%);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ellipsisTxt span {
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tn {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.tn .newCount {
  background: #4cb050bf;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  font-family: "Gilroy-Regular";
  font-size: 12px;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inboxChat {
  background: #fff;  /* Match header background */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;  /* Remove padding */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 0;  /* Ensure no gap */
  min-height: 0;
  position: relative;
}
.flex-fill.position-relative {
  flex: 1;
  overflow: hidden;
  position: relative;
  min-height: 0;
}
.chat-window-wrapper {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  height: calc(100vh - 60px - 16px);
  margin: 0;      /* Remove any margin */
}

.typing-area-wrapper {
  flex: none;
  background: white;
  padding: 8px 12px;
  border-top: 1px solid #eee;
  position: relative; /* Not sticky/absolute */
  z-index: 2;
}
.inboxChat .headItem {
  background: #ebf0f4;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  padding: 8px;
}

.inboxChat .headItem .name {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 6px;
  font-family: "Gilroy-Bold";
}

.inboxChat .headItem .mob {
  color: #000;
  font-weight: 700;
  font-size: 10px;
  font-family: "Gilroy-Bold";
}

.inboxChat .headItem > img {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-right: 10px;
}

.inboxChat .headItem .action {
  display: flex;
}

.inboxChat .headItem .action span {
  background: rgb(63 174 253 / 80%);
  color: #fff;
  border-radius: 10px;
  padding: 2px 10px;
  font-family: "Gilroy-Medium";
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.inboxChat .headItem .action *:not(:first-child) {
  margin-left: 10px;
}
.convoBody {
  flex: 1;
  position: absolute;
  inset: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
}
.inboxChat .convoBody {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 4px;
}

.inboxChat .convoBody .ant-spin-nested-loading {
  position: initial;
}

.spacer {
  flex: 1 1 auto;
}

.inboxChat .convoBody .msgIncoming {
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  color: #000000;
  align-self: flex-start;
  max-width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  width: fit-content;
}

.inboxChat .convoBody .msgArea {
  border-radius: 10px;
  padding: 10px;
  /* min-height: 54px; */
  display: flex;
  font-size: 11px;
  flex-direction: column;
  /* min-width: 250px; */
  font-family: "Gilroy-SemiBold";
}

.inboxChat .convoBody .msgArea p {
  text-align: right;
  font-family: "Gilroy-Bold";
  font-size: 11px;
  color: rgb(0 0 0 / 50%);
  margin-top: auto;
}

.inboxChat .convoBody .msgArea p span {
  margin-right: 5px;
}

.inboxChat .convoBody .msgArea p .read {
  color: #53bdeb;
}

.inboxChat .convoBody .msgIncoming .msgArea {
  background: #ebf0f4;
}

.inboxChat .convoBody .name {
  margin-bottom: 8px;
}

.inboxChat .convoBody .msgOutgoing {
  font-family: "Gilroy-SemiBold";
  font-size: 15px;
  align-self: flex-end;
  max-width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
  width: fit-content;
}

.inboxChat .convoBody .msgOutgoing .msgArea {
  background: rgba(43, 90, 160, 0.8);
  color: #fff;
}

.inboxChat .convoBody .msgOutgoing .name {
  text-align: right;
  color: #000;
}

.inboxChat .convoBody .msgOutgoing p {
  color: #fff;
}

.msgTime {
  font-family: "Gilroy-Bold";
  font-size: 14px;
  font-weight: 700;
  color: rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  padding: 30px 0;
}

.msgTime span {
  padding: 0 20px;
}

.msgTime:before,
.msgTime:after {
  content: "";
  height: 1px;
  background: rgb(0 0 0 / 20%);
  flex: 1;
}

.inboxChat textarea {
  background-color: #ebf0f4 !important;
  padding-bottom: 40px;
}

.inboxChat .typingArea .actions {
  display: flex;
  justify-content: flex-end;
  right: 5px;
  bottom: 5px;
}

.inboxChat .typingArea .actions button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0;
  background: rgb(217 217 217 / 50%);
  color: #717273;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inboxChat .typingArea .actions button:last-child img {
  width: 30px;
  height: 30px;
}

.inboxChat .typingArea .actions button:not(:first-child) {
  margin-left: 5px;
}

.inboxChat .typingArea .actions button:last-of-type {
  background: #3faefdd1;
  color: #fff;
}

.inboxDetails {
  /* border-radius: 10px; */
  /* padding: 5px 10px; */
  /* background: #fff; */
  background-color: transparent;
}

.inboxDetails_top_container {
  background-color: #ffffffb2;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 8px;
}

.inboxDetails .detailTop {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 8px;
}

.inboxDetails .detailTop span {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #3faefd;
  color: #000;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}

.inboxDetails .detailTop h2 {
  font-family: "Gilroy-Bold";
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.inboxDetails .chips {
  display: flex;
  gap: 4px;
  flex-flow: wrap;
  margin-bottom: 8px;
}

.inboxDetails .chips button {
  background: #ebf0f4;
  border: 0;
  border-radius: 10px;
  color: #00000080;
  font-family: "Gilroy-SemiBold";
  font-size: 10px;
  padding: 4px 6px;
}

.inboxDetails .opt-in {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 4px 4px;
}



.inboxDetails .opt-in:hover {
  background: #ebf0f4;
}

.inboxDetails .opt-in label {
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #000;
}

.inboxDetails .opt-in p {
  font-family: "Gilroy-SemiBold";
  font-size: 11px;
  color: #00000080;
}

.inboxDetails .opt-in button {
  margin-left: auto;
  background: #fff;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.inboxDetails .opt-in:hover button {
  visibility: visible;
}

.inboxDetails .detailsRow {
  padding: 8px;
}

.inboxDetails .detailsRow label {
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.inboxDetails .detailsRow p {
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  color: rgb(0 0 0 / 50%);
}



.inboxDetails .detailsRow .statusChip {
  display: inline-block;
  background: #2298ff;
  color: #fff;
  padding: 5px 20px;
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  border-radius: 15px;
}

.inboxDetails .detailsRow .labelChip {
  display: inline-block;
  background: #c22121;
  color: #fff;
  padding: 5px 20px;
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  border-radius: 15px;
}
/* 
@media (min-width: 1400px) {
  .userLists,
 .rightSideBox {
  max-width: 280px;
}
}
@media (min-width: 1600px) {
  .userLists,
  .rightSideBox {
    width: 440px;
  }
  .ellipsisTxt {
    max-width: 300px;
  }
}
@media (min-width: 2000px) {
  .userLists,
  .rightSideBox {
    width: 480px;
  }
  .ellipsisTxt {
    max-width: 340px;
  }
}
@media (min-width: 2400px) {
  .userLists,
  .rightSideBox {
    width: 540px;
  }
  .ellipsisTxt {
    max-width: 400px;
  }
} */

.outline-0:focus,
.outline-0:active {
  outline: none !important;
  box-shadow: 0 !important;
}
.fs-7 {
  font-size: 14px !important;
}
.fs-8 {
  font-size: 12px !important;
}
.fs-9 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-13 {
  font-size: 13px !important;
}

.hw-30 {
  height: 30px;
  width: 30px;
}
.hw-20 {
  height: 20px;
  width: 20px;
}
.hw-19 {
  height: 19px;
  width: 19px;
}
.hw-115 {
  height: 115px;
  width: 115px;
}
.hw-112 {
  height: 112px;
  width: 112px;
}
.bg-muted {
  background-color: #d9d9d9 !important;
}
.lh-normal {
  line-height: normal;
}

/* width */
.leadLists::-webkit-scrollbar,
.convoBody::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.leadLists::-webkit-scrollbar-track,
.convoBody::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 4px;
}

/* Handle */
.leadLists::-webkit-scrollbar-thumb,
.convoBody::-webkit-scrollbar-thumb {
  background: #a1a1a5;
  border-radius: 4px;
}

/* Handle on hover */
.leadLists::-webkit-scrollbar-thumb:hover,
.convoBody::-webkit-scrollbar-thumb:hover {
  background: #d8d9df;
}

.clearBtn {
  right: 35px;
  position: absolute;
  top: 0;
}

.custom-bg-primary {
  background-color: #3a4b86 !important;
}
.h-45 {
  height: 45px !important;
}

.rs-toggle .rs-toggle-presentation {
  border-radius: 12px;
  height: 20px;
  min-width: 40px;
  width: 40px;
  max-width: 40px;
}
.rs-toggle-presentation {
  background-color: transparent !important;
  border: 2px solid !important;
  border-color: #bababa !important;
}

.rs-toggle-presentation:after {
  background-color: #bababa !important;
}
.rs-toggle-checked .rs-toggle-presentation:after {
  background-color: var(--rs-toggle-checked-bg) !important;
}
.rs-toggle-checked .rs-toggle-presentation {
  border-color: var(--rs-toggle-checked-bg) !important;
}
.rs-toggle .rs-toggle-presentation:after {
  height: 10px;
  left: 3px;
  top: 3px;
  width: 10px;
}

.rs-toggle.rs-toggle-checked .rs-toggle-presentation:after {
  margin-left: -14px;
}

.rs-toggle.rs-toggle-checked .rs-toggle-presentation:active:after {
  margin-left: -14.6px;
}
.rs-toggle .rs-toggle-presentation:active:after {
  width: 10.6px;
}

.inboxChatHead {
  background-color: #ebf0f4;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: row;
  margin-bottom: 0;  /* Removed gap */
  border-bottom: 1px solid #e5e7eb;  /* Subtle separator */
}

.headItem {
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  background-color: #ffffffb2;
}

.inboxChatHead .headItem .name {
  color: #000;
  font-size: 14px;
  margin-bottom: 0px;
  font-family: "Gilroy-Bold";
}

.inboxChatHead .headItem .mob {
  color: #00000080;
  font-size: 10px;
  font-family: "Gilroy-Bold";
}

.inboxChatHead .headItem > img {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-right: 10px;
}

.inboxChatHead .headItem .action {
  display: flex;
}

.inboxChatHead .headItem .action span {
  background: rgb(63 174 253 / 80%);
  color: #fff;
  border-radius: 10px;
  padding: 2px 10px;
  font-family: "Gilroy-Medium";
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.inboxChatHead .headItem .action *:not(:first-child) {
  margin-left: 10px;
}

.search_filter_container {
  background-color: #ffffffb2;
  border-radius: 10px;
  padding: 10px;
}

.userLists .filter .search input {
  background-color: #ebf0f4 !important;
}

.active_button {
  background-color: #3faefdd1 !important;
  color: #fff !important;
  border-radius: 6px;
}
.active_button svg {
  margin-bottom: 1px;
  margin-left: 1px;
}

.chat_list_container {
  background-color: #ffffff;
  border-radius: 0 0 10px 10px; /* Rounded only at bottom */
  padding: 0;
  height: calc(100vh - 130px);
  overflow-y: auto;
}

.chat_list_container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.chat_list_container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.chat_list_container::-webkit-scrollbar-thumb {
  background: #a1a1a5; /* Handle color */
  border-radius: 4px; /* Rounded corners */
}

.chat_list_container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Handle color on hover */
}

.img_container {
  height: 160px;
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5px;
}

.img_container div {
  position: absolute;
  cursor: pointer;
  top: 42%;
  left: 42%;
}

.filter_inactive {
  color: #00000080 !important;
  background-color: #ebf0f4 !important;
}

.inbox_date {
  font-family: "Gilroy-Semibold" !important;
}

.msg_time {
  font-size: 9px;
  color: #00000080;
  font-family: "Gilroy-Semibold";
  text-align: right;
  margin: 10px 0;
  max-width: 53.2%;
}

.unread_container {
  height: 16px;
  width: 16px;
  background-color: #4cb050bf;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unread_container span {
  color: #fff;
  font-family: "Gilroy-Regular";
  font-size: 10px;
  margin-top: 1.4px;
}

.rightSideBox {
  width: 280px;
  min-width: 280px;  /* Prevent shrinking */
  max-width: 280px;  /* Force consistent width */
  padding: 0 12px;
  display: flex;
  flex-direction: column;
}
.row.h-100 {
  display: flex;
  height: 100%;
  margin: 0;
  gap: 8px;        /* Reduce gap between panels */
  padding: 0 8px;  /* Reduce outer padding */
}

/* Ensure proper flex behavior for center column */
.col.px-0 {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

/* Search Card Container - Reduced Padding */
.search-filter-card {
  background: #ffffff;
  border-radius: 10px 10px 0 0; /* Rounded only at top */
  padding: 12px 12px 8px;
  border-bottom: 1px solid #e5e7eb;
}

/* Compact Search Input */
.search-wrapper {
  position: relative;
  margin-bottom: 8px;  /* Reduced margin */
}

.search-input {
  width: 100%;
  height: 36px;  /* Reduced height */
  background: #f8f9fa;
  border: 1px solid #ebedf0;
  border-radius: 6px;
  padding: 6px 32px;  /* Reduced padding */
  font-family: 'Gilroy-Medium', sans-serif;
  font-size: 13px;
  color: #333;
  transition: all 0.2s ease;
}

.search-input::placeholder {
  color: #94969b;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #94969b;
  font-size: 13px;
}

.clear-btn {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #94969b;
  padding: 4px;
  cursor: pointer;
}

/* Compact Filter Tags */
.filter-tags-row {
  display: flex;
  align-items: center;
  gap: 6px; /* Reduced gap */
  flex-wrap: nowrap;
  margin-top: 2px;
  overflow-x: auto;
  -ms-overflow-style: none;  /* Hide scrollbar IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar Firefox */
  padding-bottom: 4px; /* Space for scroll */
}

/* Hide scrollbar for Chrome/Safari */
.filter-tags-row::-webkit-scrollbar {
  display: none;
}

.filter-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 5px 10px;
  border-radius: 6px;
  font-family: 'Gilroy-Medium', sans-serif;
  font-size: 12px;
  line-height: 1;
  border: none;
  background: #f8f9fa;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-height: 28px; /* Ensure consistent height */
}

.filter-tag:hover {
  background: #f0f2f5;
}

.filter-tag.active {
  background: #3FAEFD;
  color: white;
}

.filter-tag i {
  font-size: 11px;
}

.add-filter-btn {
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #f8f9fa;
  border: none;
  cursor: pointer;
  color: #3FAEFD;
  margin-left: 4px;
  flex-shrink: 0;
}

/* Updated Chat List Container */
.chat_list_container {
  height: calc(100vh - 140px); /* Adjust based on your header height */
  overflow-y: auto;
  padding: 0;
  margin: 0;
}

/* Optimize scrollbar style */
.chat_list_container::-webkit-scrollbar {
  width: 6px;
}

.chat_list_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat_list_container::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 3px;
}

.chat_list_container::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}
/* Add these to whatsapp.css */
.message-bubble {
  max-width: 75%;
  margin: 1px 8px; /* Reduced side margins */
  position: relative;
}

/* New thin tail styles */
.message-bubble::before {
  content: '';
  position: absolute;
  top: 0;
  width: 8px; /* Thinner tail */
  height: 8px; /* Smaller tail */
}

.incoming .message-bubble {
  background: #ffffff;
  border-top-left-radius: 0;
}

.incoming .message-bubble::before {
  left: -4px;
  background: linear-gradient(45deg, #ffffff 50%, transparent 50%);
}

.outgoing .message-bubble {
  background: #0084ff; /* Changed to blue */
  border-top-right-radius: 0;
  color: white; /* Added for better contrast */
}

.outgoing .message-bubble::before {
  right: -4px;
  background: linear-gradient(135deg, #0084ff 50%, transparent 50%); /* Matched with new blue */
}

.message-meta {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
  font-size: 11px;
  color: #667781;
  float: right;
}

.message-status {
  display: flex;
  align-items: center;
}

.message-content {
  word-break: break-word;
  color: #111b21;
  font-size: 14px;
  line-height: 19px;
}

.message-time {
  margin-left: 4px;
  font-size: 11px;
}

.message-sender {
  font-size: 12px;
  color: #1fa855;
  margin-bottom: 2px;
}
/* Messages Container */
.messages-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 2px;
}

/* Message Styles */
.message-container {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  margin: 2px 15px;
  position: relative;
}

.message-container.incoming {
  align-self: flex-start;
}

.message-container.outgoing {
  align-self: flex-end;
}

.message-bubble {
  position: relative;
  padding: 8px 12px;
  border-radius: 7.5px;
  background: #fff;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}

.incoming .message-bubble {
  border-top-left-radius: 0;
}

.outgoing .message-bubble {
  background: #c6d3f8;
  border-top-right-radius: 0;
}

/* Bubble Tails */
.message-bubble::before {
  content: '';
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
}

.incoming .message-bubble::before {
  left: -7px;
  border-right: 12px solid #fff;
  border-bottom-right-radius: 16px;
}

.outgoing .message-bubble::before {
  right: -7px;
  border-left: 12px solid #dcf8c6;
  border-bottom-left-radius: 16px;
}

/* Message Content */
.message-content {
  font-size: 13.2px;
  line-height: 19px;
  color: #303030;
}

.message-footer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 2px;
  justify-content: flex-end;
}

.message-time {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.45);
}

.message-status {
  color: rgba(0, 0, 0, 0.45);
}

.message-status .read {
  color: #53bdeb;
}

/* Media Content */
.media-container {
  position: relative;
  width: 300px;
  max-width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: #f0f2f5;
}

.media-content {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.media-content.document {
  background: white;
  padding: 10px;
}

.download-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
}

.download-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Date Divider */
.date-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}

.date-text {
  background: rgba(225, 245, 254, 0.92);
  padding: 5px 12px;
  border-radius: 7.5px;
  font-size: 12.5px;
  color: #54656f;
}

/* Empty State */
.empty-chat {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f2f5;
}

.empty-chat-content {
  text-align: center;
  padding: 20px;
}

.empty-chat-content h3 {
  font-size: 20px;
  color: #41525d;
  margin-bottom: 10px;
}

.empty-chat-content p {
  color: #667781;
  font-size: 14px;
  max-width: 500px;
  margin: 0 auto;
}

.messages-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 4px 8px;
}

.message {
  display: flex;
  margin: 1px 8px;
  max-width: 75%;
  padding: 0;
}


.message.outgoing {
  justify-content: flex-end;
  margin-left: auto;
}

.message-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.message.incoming .message-container {
  margin-left: 4px;  /* Reduced from previous larger padding */
}
.message.incoming {
  padding-right: 40px; /* Give space on right for incoming */
}
.message.outgoing {
  padding-left: 40px; /* Give space on left for outgoing */
  margin-left: auto;
}
.date-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  gap: 12px;
}

.date-line {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  flex: 1;
}

.date-bubble {
  background: #E2E8F0;
  padding: 4px 12px;
  border-radius: 16px;
}

.date-text {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  color: #64748B;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.message {
  display: flex;
  margin: 2px 0;
  padding: 0 16px;
}

.message.outgoing {
  justify-content: flex-end;
}

.message-container {
  max-width: 65%;
  position: relative;
  margin: 0;
  padding: 0;
}

.message-sender {
  font-size: 11px;
  color: #64748B;
  margin-bottom: 2px;
  padding-left: 12px;
}

.message-content {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 8px 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  position: relative;
}

.outgoing .message-content {
  background: #c6d3f8;
}

.message-footer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 2px;
}

.message-time {
  font-size: 11px;
  color: #ffffff;
  opacity: 0.7;
}
.outgoing .message-time {
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.7;
}

.incoming .message-time {
  color: rgba(0, 0, 0, 0.45);
  opacity: 0.7;
}
.message-status {
  display: flex;
  align-items: center;
  color: #64748B;
}

.message-status .read {
  color: #53BDEB;
}

/* Message tail/arrow */
.message-content::before {
  content: '';
  position: absolute;
  top: 0;
  width: 8px;  /* Reduced from 12px */
  height: 8px;
  transform: rotate(45deg);
  display: none;
}

.message.incoming .message-content::before {
  left: -4px;
  background: #ffffff;
}

.message.outgoing .message-content::before {
  right: -4px;
  background: #5a6ab2;
}


/* Hover state */
.message-content:hover {
  .message-actions {
    opacity: 1;
  }
}

.message-actions {
  position: absolute;
  right: 8px;
  top: -24px;
  opacity: 0;
  transition: opacity 0.2s;
  display: flex;
  gap: 8px;
  background: white;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.media-wrapper {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.video-container,
.image-container {
  width: 100%;
  aspect-ratio: 16/9;
  background: #F1F5F9;
  position: relative;
}

.media-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.document-container {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px;
}

.document-info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.document-details {
  display: flex;
  flex-direction: column;
}

.document-name {
  font-size: 14px;
  font-weight: 500;
}

.document-size {
  font-size: 12px;
  color: #64748B;
}

.document-preview {
  width: 100%;
  height: 200px;
  border: none;
  border-radius: 4px;
}

.download-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.download-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.media-loading,
.media-error {
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F1F5F9;
  gap: 8px;
}

.media-error {
  color: #EF4444;
}
.media-preview-modal {
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
  
  .ant-modal-close {
    color: white;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    padding: 8px;
  }

  .preview-content {
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain;
  }
}
/* Add to whatsapp.css */
.message-text-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  line-height: 1.4;
}

.message-content {
  padding: 4px 6px 4px 6px; /* Equal padding all around */
  border-radius: 12px;
  max-width: 100%;
  position: relative;
}

.message-content a {
  color: #0084ff;
  text-decoration: none;
}

.message-content a:hover {
  text-decoration: underline;
}

/* Preserve consecutive spaces */
.message-text-content:not(a) {
  white-space: pre-wrap;
}
/* Add to whatsapp.css */

/* Floating date header */
.floating-date-header {
  position: sticky;
  top: 16px;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding: 0 8px;
  pointer-events: none;
}

.date-bubble {
  background: rgba(225, 245, 254, 0.92);
  backdrop-filter: blur(4px);
  padding: 4px 12px;
  border-radius: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin: 8px 0;
}

.date-text {
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 12px;
  color: #54656f;
}

/* Message container improvements */
.messages-container {
  padding: 4px 8px; /* Reduced padding */
  display: flex;
  flex-direction: column;
  gap: 2px; /* Tighter message spacing */
}

.message-bubble {
  max-width: 85%; /* Wider bubbles */
  padding: 6px 8px; /* More compact padding */
  margin: 1px 0; /* Reduced vertical spacing */
}

.message-container {
  margin: 0;         /* Remove default margins */
  max-width: 100%;
}

.message-time {
  font-size: 11px;
  color: rgba(255, 249, 249, 0.7); /* Better contrast for blue bubbles */
  margin-left: 4px;
  display: inline-block;
}

/* Hide duplicate timestamp */
.message-meta .message-time {
  display: none;
}

/* Background colors */
.incoming .message-bubble {
  background: #ffffff;
}

.outgoing .message-bubble {
  background: #5a6ab2;
}

/* Message content spacing */
.message-content {
  padding: 4px 6px;
  line-height: 1.4;
}
.message.incoming .message-content {
  background: #ebf0f4;
  border-top-left-radius: 2px;
}


.message.incoming .message-content::after {
  content: '';
  position: absolute;
  top: 0;
  left: -6px;
  width: 8px;
  height: 16px;
  background: #ffffff;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.message.outgoing .message-content {
  border-top-right-radius: 2px;
}

.message.outgoing .message-content::after {
  content: '';
  position: absolute;
  top: 0;
  right: -6px;
  width: 8px;
  height: 16px;
  background: #5a6ab2; /* Match your outgoing message color */
  clip-path: polygon(0 0, 0 100%, 100% 0);
}
.message.outgoing .message-content {
  background: #5a6ab2;
  color: #fff;
  border-top-right-radius: 2px;
}
/* Date separator styling */
.message-date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0 8px;
  position: relative;
}
/* Group styling */
.message.first-in-group {
  margin-top: 8px;
}

.message.last-in-group {
  margin-bottom: 8px;
}

.message:not(.first-in-group) .message-content::before {
  display: none;
}

.message-text-content {
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* Add new styles for media preview */
.media-preview-container {
  width: 300px; /* Make bigger */
  max-height: 400px;
  border-radius: 8px;
  overflow: hidden;
  background: #f0f2f5;
  margin: 8px 0;
  cursor: pointer;
}

.media-load-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #f8fafc;
  border: 1px dashed #e2e8f0;
}

/* Modal styles */
.media-preview-modal {
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  
  .ant-modal-close {
    position: fixed;
    top: 16px;
    right: 16px;
    color: white;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    padding: 8px;
    z-index: 1000;
  }
}
.template-selector {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  flex: 1;
}

.template-card {
  background: white;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #eee;
  cursor: pointer;
}

.template-card.selected {
  border: 2px solid #3FAEFD;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.current-page {
  margin: 0 15px;
}

.template-actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 1px solid #eee;
}