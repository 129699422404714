.lead_cell {
  font-family: Gilroy-Bold;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lead_input {
  height: 34px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0 10px;
}
/* Add to src/components/leads/leads.css */

.lead-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.lead-header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.lead-header-title h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  font-family: "Gilroy-Bold", sans-serif;
}

.lead-header-title h5 {
  font-size: 1rem;
  color: #6b7280;
  margin: 0;
}

.lead-header-options {
  background: none !important;
  border: none !important;
  color: #374151 !important;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
}

.lead-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: #f3f4f6;
  color: #374151;
  transition: all 0.2s;
}

.action-button:hover {
  background: #e5e7eb;
}

li.disabled .action-button {
  opacity: 0.5;
  cursor: not-allowed;
  background: #e5e7eb;
}

.lead-details-info {
  margin-top: 1rem;
  padding: 0 1rem;
}

.modal-backdrop.show {
  opacity: 0.5;
  z-index: 1054;
}



/* leads.css - Add bulk action button styles */
.bulk-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EBF0F4;
  border-radius: 6px;
  height: 26px;
  width: 27px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.bulk-action-button:hover {
  background-color: #e0e5ea;
}

/* Update leads controls group */
.leads-controls-group {
  display: flex;
  align-items: center;
  gap: 5px;
}


/* Core Grid Styling */
.ag-theme-alpine {
  --ag-header-height: 34px;
  --ag-row-height: 40px; /* Reduced from 48px */
  --ag-header-foreground-color: #374151;
  --ag-font-family: 'Gilroy-Medium', sans-serif;
  --ag-font-size: 13px; /* Reduced from 14px */
  --ag-cell-horizontal-padding: 12px;
  --ag-selected-row-background-color: rgba(37, 99, 235, 0.05);

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ag-paging-panel {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background: white !important;
  z-index: 100 !important;
  padding: 1rem !important;
  border-top: 1px solid #e5e7eb !important;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.05) !important;
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  padding-bottom: 42px;
}

.ag-paging-button {
  color: #374151 !important;
  background: white !important;
  border: 1px solid #d1d5db !important;
  border-radius: 6px !important;
  padding: 0.5rem 1rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.ag-paging-button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.ag-paging-description {
  color: #374151 !important;
  font-size: 0.875rem !important;
}

/* Header Styling */
.ag-header {
  border-bottom: 1px solid #E5E7EB;
}

.ag-header-cell {
  padding: 0 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 48px;
  color: #374151;
}

.ag-header-cell-label {
  color: #374151;
  font-size: 13px;
  letter-spacing: 0.01em;
}

.ag-paging-page-size-select {
  height: 32px !important; /* Reduced height */
  padding: 0 8px !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 6px !important;
  background: white !important;
  min-width: 70px !important;
  font-size: 13px !important;
}


/* Row Styling */
.ag-row {
  border-bottom: 1px solid #F3F4F6;
  transition: background-color 0.15s ease;
}

.ag-row-hover {
  background-color: #F9FAFB !important;
}

.ag-row-selected {
  background-color: rgba(37, 99, 235, 0.05) !important;
}

/* Cell Styling */
.ag-cell {
  line-height: 42px; /* Match row height */
  padding: 0 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Name Column - Expanded width and ellipsis */
.ag-cell[col-id="name"] {
  font-weight: 500;
  color: #111827;
  font-size: 13px;
  padding-right: 24px; /* More space for text */
}

/* Actions Column */
.ag-cell[col-id="actions"] {
  display: flex;
  gap: 4px;
  padding: 0 8px;
}

.action-icon {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7280;
  background: transparent;
  transition: all 0.15s ease;
}

.action-icon:hover {
  background-color: #F3F4F6;
  color: #374151;
}

/* Status Pills */
.status-pill {
  padding: 3px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  height: 22px;
}

.status-pill.pending {
  background-color: #312E81;
  color: white;
}

.status-pill.hot {
  background-color: #10B981;
  color: white;
}

/* Checkbox Styling - Larger & More Visible */
.ag-checkbox-input-wrapper {
  width: 20px; /* Increased from 18px */
  height: 20px; /* Increased from 18px */
  border: 2px solid #D1D5DB;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  margin-right: 8px;
  transition: all 0.2s ease;
}

.ag-checkbox-input-wrapper.ag-checked {
  background-color: #2563EB;
  border-color: #2563EB;
}

.ag-checkbox-input-wrapper.ag-checked::after {
  color: white;
  font-weight: bold;
  font-size: 12px;
}

/* Source Column */
.ag-cell[col-id="source"] {
  font-size: 12px;
  color: #6B7280;
}

.source-icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

/* Created Date Column */
.ag-cell[col-id="createdDate"] {
  color: #6B7280;
  font-size: 12px;
}

/* Organization Name Column */
.ag-cell[col-id="orgName"] {
  font-size: 12px;
  color: #4B5563;
}

/* Last Activity Column */
.ag-cell[col-id="lastActivity"] {
  font-size: 12px;
  color: #6B7280;
}

/* Label Column */
.ag-cell[col-id="label"] {
  padding: 0 8px;
}

.label-tag {
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
  background-color: #F3F4F6;
  color: #374151;
}

/* Selection Column */
.ag-cell[col-id="selection"] {
  padding: 0 4px;
  width: 48px; /* Fixed width for checkbox column */
}

/* Empty State */
.ag-overlay-no-rows-center {
  padding: 24px;
  color: #6B7280;
  font-size: 13px;
}

/* Ensure text truncation works */
.cell-text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Row hover effects */
.ag-row:hover {
  background-color: #F9FAFB;
}

/* Assigned To Column */
.ag-cell[col-id="assignedTo"] {
  font-size: 12px;
  color: #4B5563;
}

/* Custom scrollbar for better visibility */
.ag-body-viewport::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-body-viewport::-webkit-scrollbar-track {
  background: #F3F4F6;
}

.ag-body-viewport::-webkit-scrollbar-thumb {
  background: #D1D5DB;
  border-radius: 4px;
}

.ag-body-viewport::-webkit-scrollbar-thumb:hover {
  background: #9CA3AF;
}