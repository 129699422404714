/* ActivityFeed.css */

body {
    font-family: 'Poppins', sans-serif;
}



.activity-timeline {
    position: relative;
}

.activity-card {
    display: flex;
    padding: 20px;
    border-radius: 8px;
    transition: transform 0.2s;
    background-color: #e7f1ff;
}

.activity-card:hover {
    transform: translateY(-4px);
}

.activity-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: #e7f1ff;
    font-size: 1.5rem;
    color: #007bff;
}

.activity-info {
    flex-grow: 1;
}

.activity-title {
    font-weight: 600;
    color: #333;
}

.open-details-btn {
    color: #007bff;
    transition: color 0.2s;
}

.open-details-btn:hover {
    color: #0056b3;
}
.activity-feed-container {
    font-family: 'Gilroy', sans-serif;
    position: relative;
    padding-top: 0px;
    
}
.timeline-line {
    position: absolute;
    left: 50px; /* Adjust to align with icons */
    top: 0;
    bottom: 0;
    width: 2px;
    background: repeating-linear-gradient(
        to bottom,
        #ccc,
        #ccc 4px,
        transparent 4px,
        transparent 8px
    );
}

.activity-feed-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px; /* Reduced padding */
    margin-bottom: 8px; /* Reduced margin */
}
.activity-count {
    background-color: #007bff;
    color: #fff;
    padding: 3px 8px; /* Reduced padding */
    border-radius: 15px;
    font-size: 14px; /* Slightly smaller font size */
    margin-left: 8px;
}

.filter-bar {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Flexible grid layout */
    gap: 12px;
    padding: 8px 18px;
    margin: 0 auto; /* Center the filter bar */
    margin-bottom: 16px;
    background-color: #e7f1ff;
    max-width: 500px; /* Restrict to 600px max width */
    box-sizing: border-box; /* Ensure padding doesn't expand beyond max-width */
}
.custom-dropdown .dropdown-toggle {
    border-radius: 20px;
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
}

.custom-select {
    border-radius: 20px;
    padding: 8px 15px;
    background-color: #f5f5f5;
    border: none;
}


.floating-date-bar {
    position: absolute;
    top: 50px; /* Adjust this value based on your design */
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: bold;
    z-index: 10;
}


.activity-list {
    max-height: 600px;
    overflow-y: scroll;
    position: relative;
    padding: 10px 0;
}

.timeline-line {
    position: absolute;
    top: 0;
    left: 30px;
    width: 2px;
    height: 100%;
    background-image: linear-gradient(180deg, #ddd 25%, transparent 0%);
    background-size: 2px 20px;
    z-index: 0;
}

.activity-card {
    position: relative;
    margin-bottom: 15px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.activity-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.activity-icon {
    background-color: #f0f8ff;
    border-radius: 50%;
    padding: 10px;
    font-size: 1.2rem;
    color: #007bff;
}

.open-details-btn {
    color: #007bff;
    transition: color 0.2s;
}

.open-details-btn:hover {
    color: #0056b3;
}

.pagination-controls {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 14px;
}
.activity-feed-title {
    font-size: 20px; /* Slightly smaller font */
    font-weight: 600;
    color: #333;
    margin: 0;
}