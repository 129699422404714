.whatsapp-broadcast a {
  background: #3faefdd1;
  /* padding: 0 10px; */
}

.auto-section-1 p {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  font-weight: 600;
  color: #000;
  margin-top: 2px; /* Decrease spacing above */
  margin-bottom: 2px; /* Decrease spacing below */
  padding-top: 0; /* Remove any internal padding if there is any */
  padding-bottom: 0; /* Remove any internal padding if there is any */
}




.verticalTab .tab {
  border-radius: 5px;
  padding: 10px;
  font-family: "Gilroy-Medium";
  font-size: 16px;
  font-weight: 700;
  color: #000;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.verticalTab .tab:not(:first-child) {
  margin-top: 5px;
}

.verticalTab .tab img {
  margin-right: 10px;
}

.verticalTab .tab.active {
  color: #3faefdd1;
  background-color: #fff;
}

.div-table {
  display: grid;
  width: auto;
  background-color: #eee;
  gap: 5px;
}
.div-table-row {
  display: flex;
}
.div-table-col {
  width: 22%;
  min-height: 42px;
  background-color: #fff;
  padding: 10px;
  font-family: "Gilroy-Bold";
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.div-table-row .div-table-col:first-child {
  border-radius: 10px 0 0 10px;
}

.div-table-row .div-table-col:last-child {
  border-radius: 0 10px 10px 0;
}

.broadcast_type_container {
  text-transform: capitalize;
}

.broadcast_type_container img {
  height: 18px;
  width: 18px;
  margin-right: 5px;
  object-fit: contain;
}

/* Chip Base Styling */
.broadcast_status_container {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  height: 24px; /* Fixed height for uniform sizing */
  border-radius: 16px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 12px;
  color: #fff; /* Default text color */
  background-color: #ffffffb8; /* Default background color */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.broadcast_status_container:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Status-Specific Styles */
.broadcast_status_container.pending {
  background-color: #d28f12; /* Orange */
}



.broadcast_status_container.failed {
  background-color: #FF4747; /* Red */
}

.broadcast_status_container.completed {
  background-color: #09890d;; /* Blue */
}

.broadcast_status_container.cancelled {
  background-color: #e3e8f4db; /* Gray */
}

/* Icon Styling */
.broadcast_status_container .status-icon {
  margin-right: 6px;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .broadcast_status_container {
    font-size: 10px;
    padding: 3px 10px;
    height: 20px; /* Adjusted height for smaller screens */
  }

  .broadcast_status_container .status-icon {
    font-size: 12px;
  }
}
.messaging-limit {
  font-size: 14px;
  font-weight: 700;
  color: #555;
  background-color: #f8f9fa;
  padding: 6px 12px; /* Adds internal spacing */
  height: 36px; /* Matches button height */
  line-height: normal; /* Ensures consistent line height */
  border-radius: 4px;
  margin-left: auto; /* Pushes the element to the right */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: inline-flex; /* Keeps it inline with adjacent elements */
  align-items: center; /* Vertically centers the text */
  justify-content: flex-start; /* Aligns the text within the box */
}
/* Add these to your existing whatsappBroadcast.css */

/* Add styles for Ant Design Table to match AG Grid's appearance */
.ant-table {
  background: #fff;
  font-family: inherit;
}

.ant-table-thead > tr > th {
  background: #f8f9fa;
  font-weight: 500;
  padding: 12px 8px;
  font-size: 14px;
}

.ant-table-tbody > tr > td {
  padding: 12px 8px;
  font-size: 14px;
}

/* Preserve existing hover behavior */
.ant-table-tbody > tr:hover > td {
  background-color: rgba(63, 174, 253, 0.05);
}

/* Maintain existing padding and alignment */
.ant-table-cell {
  padding: 12px 8px !important;
}

/* Keep existing status container styles */
.broadcast_status_container {
  /* Your existing styles remain unchanged */
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  height: 24px;
  border-radius: 16px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 12px;
  color: #fff;
}

/* Keep existing type container styles */
.broadcast_type_container {
  
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 8px;
}

.broadcast_type_container img {
  /* Your existing styles remain unchanged */
  height: 18px;
  width: 18px;
  margin-right: 5px;
  object-fit: contain;
  
}
/* whatsappBroadcast.css */
.whatsapp-broadcast {
  background-color: #f5f7f9;
  min-height: calc(100vh - 4px);
}

.auto-section-1 {
  margin-bottom: 1rem;
}

.auto-section-1-sub-1 label {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.messaging-limit {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  background-color: #f8f9fa;
  padding: 6px 12px;
  border-radius: 4px;
  margin: 0;
}


.auto_btn {
  background: #3FAEFD;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.auto_btn:hover {
  background: #2d8fd9;
  color: white;
}

.campaign-table-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 0;
  margin-top: 4px;
}

.campaigns-table .ant-table-thead > tr > th {
  background: #f8f9fa !important;
  font-weight: 600;
  padding: 8px 12px !important;
  font-size: 14px;
  border-bottom: 1px solid #e8e8e8;
}

.campaigns-table .ant-table-tbody > tr > td {
  padding: 8px 16px !important;
  height: 45px;
}

.campaigns-table .ant-table-tbody > tr:hover > td {
  background-color: rgba(63, 174, 253, 0.05);
}

.broadcast_type_container {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
}

.broadcast_type_container img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.broadcast_status_container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  height: 24px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  color: white;
  text-transform: capitalize;
}

.campaigns-table .ant-table-pagination {
  margin: 16px 0;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
  background: white;
}

.ant-table-wrapper {
  height: calc(100vh - 180px);
}

.ant-table-body {
  height: calc(100vh - 280px) !important;
}

.campaign-tabs {
  padding: 0 16px;
  margin-bottom: 0;
  border-bottom: 1px solid #f0f0f0;
}

.campaign-tabs .ant-tabs-nav {
  margin: 0;
}

.campaign-tabs .ant-tabs-tab {
  padding: 12px 0;
  font-size: 14px;
  margin: 0 24px 0 0;
}

.campaign-tabs .ant-tabs-tab-active {
  font-weight: 500;
}

.campaign-tabs .ant-tabs-ink-bar {
  background: #1890ff;
  height: 2px;
}

.campaigns-table {
  padding: 0 16px;
}

.campaigns-table .ant-table-thead > tr > th {
  background: #f8f9fa !important;
  font-weight: 600;
  padding: 12px 16px !important;
  font-size: 14px;
  border-bottom: 1px solid #e8e8e8;
}

.campaigns-table .ant-table-tbody > tr > td {
  padding: 12px 16px !important;
}

.campaigns-table .ant-table-tbody > tr:hover > td {
  background-color: rgba(63, 174, 253, 0.05);
}
.ant-empty-normal {
  margin: 32px 0;
}

.ant-empty-description {
  color: #8c8c8c;
  font-size: 14px;
}


.campaign-view-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1050;
  overflow-y: auto;
}
.campaign-view-container {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}