.selection_container {
  display: flex;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  gap: 4px;

  button {
    background-color: transparent;
    border: none;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 6px 12px;
    font-family: "Gilroy-Semibold";
    font-size: 14px;
    line-height: 14px;
    color: #000;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f8f9fa;
      border-radius: 6px;
    }

    img,
    svg {
      margin-right: 6px;
      width: 16px;
      height: 16px;
      margin-bottom: 1px;
    }

    img {
      height: 14px;
      width: 14px;
      object-fit: contain;
    }

    &:last-child {
      border-right: 0px;
    }

    // Delete button special styling
    &:first-child {
      svg {
        color: #df3737;
      }
      &:hover {
        background-color: rgba(223, 55, 55, 0.05);
      }
    }

    // Bulk task button special styling 
    &:nth-child(7) {
      svg {
        color: #128807;
      }
      &:hover {
        background-color: rgba(18, 136, 7, 0.05);
      }
    }

    // Campaign button special styling
    &:nth-child(8) {
      svg {
        color: #3faefd;
      }
      &:hover {
        background-color: rgba(63, 174, 253, 0.05);
      }
    }
  }

  // Responsive styles
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 8px;

    button {
      font-size: 13px;
      padding: 5px 10px;
      
      img, svg {
        margin-right: 4px;
        width: 14px;
        height: 14px;
      }
    }
  }
}