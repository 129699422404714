/* src/components/CallLogsScreen.module.css */

.layout {
  min-height: 100vh;
}

.header {
  background: #001529;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerTitle {
  color: #fff;
  font-family: 'Gilroy', sans-serif;
}

.content {
  padding: 20px;
  background: #f0f2f5;
  margin-left: 250px; /* Adjust based on Sidebar width */
  overflow: auto; /* Ensure scrollable */
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.searchInput {
  flex: 1;
  margin-right: 10px;
  border-radius: 8px;
}

.refreshButton {
  white-space: nowrap;
  border-radius: 8px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.endMessage {
  text-align: center;
  margin-top: 20px;
  color: #888;
  font-family: 'Gilroy', sans-serif;
}

.callLogsList {
  padding-bottom: 20px;
}

.emptyState {
  text-align: center;
  padding: 50px 0;
  color: #888;
  font-family: 'Gilroy', sans-serif;
}
/* src/components/CallLogsScreen.module.css */

.layout {
  height: 100vh; /* Adjust as needed */
}

.header {
  background: #fff;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  margin: 0;
}

.addButton {
  /* Optional styling for the button */
}

.content {
  padding: 16px;
  overflow: auto;
  height: calc(100% - 64px); /* Adjust based on header height */
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.searchInput {
  flex: 1;
  margin-right: 8px;
}

.refreshButton {
  flex: none;
}

.loader,
.endMessage,
.emptyState {
  text-align: center;
  margin: 20px 0;
}

.callLogsList {
  /* Optional styling for the list */
}
/* src/components/CallLogsScreen.module.css */

.layout {
  height: 100vh; /* Adjust as needed */
}

.header {
  background: #fff;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  margin: 0;
}

.addButton {
  /* Optional styling for the button */
}

.content {
  padding: 16px;
  overflow: auto;
  height: calc(100% - 64px); /* Adjust based on header height */
}

.placeholder {
  text-align: center;
  margin-top: 50px;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.searchInput {
  flex: 1;
  margin-right: 8px;
}

.refreshButton {
  flex: none;
}

.loader,
.endMessage,
.emptyState {
  text-align: center;
  margin: 20px 0;
}

.callLogsList {
  /* Optional styling for the list */
}
