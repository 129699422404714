/* Activity Timeline Container */
.detail_activity_scroll {
  padding: 20px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  background-color: #FFFFFF;
  position: relative;
}

/* Main timeline line */


/* Activity Item */
.activity-item {
  position: relative;
  margin-bottom: 24px;
  padding-left: 44px;
  min-height: 80px;
  display: flex;
  align-items: flex-start;
  transform: translateZ(0);
  isolation: isolate;
  width: 100%;
}

/* Activity Timeline Dot */
.activity-icon {
  position: absolute;
  left: 14px;
  top: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #FFFFFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  z-index: 2;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.activity-icon svg {
  width: 14px !important;
  height: 14px !important;
  color: #FFFFFF;
  stroke-width: 2px;
  min-width: 14px;
  min-height: 14px;
}

.activity-icon svg {
  width: 14px;
  height: 14px;
  color: #FFFFFF;
}

/* Activity Content Card */
.activity-content {
  flex: 1;
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  border-radius: 10px;
  padding: 16px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
  z-index: 1;
  width: 100%;
  transform-origin: top;
}

.activity-content:hover {
  border-color: #CBD5E1;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

/* Activity Header with Time */
.activity-time {
  font-size: 14px;
  color: #64748B;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Activity Description */
.activity-description {
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: #334155;
}

/* Note Content */
.note-content {
  margin-top: 8px;
  padding: 12px;
  background: #F8FAFC;
  border-radius: 6px;
  font-size: 14px;
  color: #475569;
}

/* Edit Button */
.edit-button {
  background: transparent;
  border: none;
  padding: 4px;
  color: #94A3B8;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  border-radius: 4px;
}

.activity-content:hover .edit-button {
  opacity: 1;
}

.edit-button:hover {
  background: #F1F5F9;
  color: #475569;
}

/* Creator Info */
.creator-info {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #E2E8F0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #64748B;
}

.creator-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #F1F5F9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #475569;
}

/* Background Variations */
.bg-activity-purple .activity-content {
  background: #F9F5FF;
  border-color: #E9D5FF;
}

.bg-activity-blue .activity-content {
  background: #F0F9FF;
  border-color: #BAE6FD;
}

/* Status Badges */
.badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.badge.bg-light {
  background: #F1F5F9;
  color: #475569;
}

.badge.bg-primary {
  background: #EFF6FF;
  color: #2563EB;
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 40px 20px;
}

/* Scrollbar Styling */
.detail_activity_scroll::-webkit-scrollbar {
  width: 6px;
}

.detail_activity_scroll::-webkit-scrollbar-track {
  background: #F1F5F9;
}

.detail_activity_scroll::-webkit-scrollbar-thumb {
  background: #CBD5E1;
  border-radius: 3px;
}

/* Add Activity Button */
.popup-section-4-add-11 {
  margin-bottom: 20px;
}

.popup-section-4-add-11 .btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #2563EB;
  color: #FFFFFF;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}