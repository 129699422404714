.create_template label {
  font-size: 16px;
  font-family: "Gilroy-Bold";
  font-weight: 700;
  color: #000;
}

.create_template input,
.create_template select,
.create_template textarea {
  background-color: #fff;
  border: 0;
  border-radius: 10px;
  font-family: "Gilroy-Medium";
  font-weight: 500;
  /* resize: none; */
  width: 100%;
  font-size: 12px;
}

.create_template input:focus,
.create_template select:focus,
.create_template textarea:focus {
  border: 0;
}

.section_block {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 20px 20px;
}

.section_block:not(:first-of-type) {
  margin-top: 20px;
}
.section_block .section_heading {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.section_block .section_heading button {
  background: #ebf0f4;
  color: #3faefdd1;
  font-family: "Gilroy-Bold";
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  padding: 5px 15px;
  border: 1px solid #3faefdd1;
}

.radioInputs {
  display: flex;
  gap: 5px;
  flex-flow: row nowrap;
}
.radioInputs label {
  margin-right: 15px;
}

.radioInputs label input {
  position: absolute;
  opacity: 0;
}

.radioInputs label span {
  position: relative;
  padding-left: 20px;
  font-size: 14px;
}

.radioInputs label span:before {
  content: "";
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #000;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.radioInputs label span:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(0);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  left: 4px;
  transition: 200ms transform;
  background: #000;
}

.radioInputs label input:checked + span:after {
  transform: translateY(-50%) scale(1);
}

.uploadFile p {
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  color: #000;
}
.textHeader input {
  background: #ebf0f4;
  border-radius: 10px;
  padding: 5px 5px 5px 15px;
  border: 0;
  height: 50px;
}

.textHeader input:focus {
  outline: 0;
}

.fileSelectInput {
  position: relative;
  background: #ebf0f4;
  border-radius: 10px;
  padding: 5px 5px 5px 15px;
  display: flex;
  align-items: center;
}

.fileSelectInput label {
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  color: rgb(0 0 0 / 50%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px 50px 5px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fileSelectInput button {
  margin-left: auto;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 10px;
  border: 0;
}

.note {
  font-family: "Gilroy-Bold";
  font-size: 10px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
}
.charLength {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  font-weight: 700;
  color: rgb(0 0 0 / 50%);
  margin-top: 5px;
  text-align: right;
  margin-left: auto;
}

.whatsappScreen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 0.3;
  -ms-flex: 0.3;
  flex: 0.3;
  min-height: 540px;
  position: -webkit-sticky;
  border: 1px solid #edf2f7;
  position: sticky;
  overflow: hidden;
  border-radius: 24px;
  top: 70px;
}

.whatsappScreen .whatsHead {
  background: #008069;
  width: 100%;
  padding: 16px;
  gap: 0.5rem;
  height: 50px;
}

.whatsapp-logo {
  width: 20px;
  height: 20px;
}

.verified {
  width: 12px;
  height: 12px;
}

.arrow-icon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.arrow-icon.expanded {
  transform: rotate(-180deg);
}

.back-icon {
  color: #fff;
  height: 14px;
  width: 14px;
  margin-right: 20px;
}

.whatsBody {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.5rem;
  background-image: url("../../../../public/assets/img/wa-pattern.png");
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 48px;
  max-width: 100%;
  width: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 480px;
}

.whatsFooter {
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 5px;
}
.whatsFooter > div {
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  background: #fff;
  gap: 0.5rem;
  border-radius: 9999px;
  height: 45px;
  flex: 1;
}

.whatsFooter > div input:focus {
  outline: none;
}

.smiley-icon {
  color: #a0aec0;
  width: 24px;
  height: 24px;
}

.file-icon {
  color: #a0aec0;
  width: 16px;
  height: 16px;
}

.camera-icon {
  color: #a0aec0;
  width: 20px;
  height: 20px;
}

.whatsBody {
  display: flex;
  align-items: flex-end;
}

.whatsBody .whatsBody_message {
  background-color: #05a884;
  color: white;
  padding: 0.5rem;
  border-radius: 10px;
  margin-right: -5px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag.open {
  background: transparent !important;
}
.buttons-container {
  margin-top: 1rem;
}

.button-item {
  background: #ebf0f4;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.drag-handle {
  cursor: grab;
  padding: 0.5rem;
}

.drag-handle:active {
  cursor: grabbing;
}

/* Maintain existing styling for form elements */
.button-item input,
.button-item select {
  background-color: #fff;
  border: 0;
  border-radius: 10px;
  font-family: "Gilroy-Medium";
  font-weight: 500;
  width: 100%;
  font-size: 12px;
}

.button-item .form-label {
  font-size: 16px;
  font-family: "Gilroy-Bold";
  font-weight: 700;
  color: #000;
}
.button-row {
  background-color: #ebf0f4;
  transition: background-color 0.2s;
}

.button-row:hover {
  background-color: #e4e9ed;
}

.drag-handle {
  cursor: grab;
  display: flex;
  align-items: center;
  padding: 4px;
}

.drag-handle:active {
  cursor: grabbing;
}

/* Phone Input Customization */
.phone-input-container {
  display: flex;
  align-items: center;
}

.phone-select-button {
  background: transparent !important;
  border: none !important;
  padding: 0 8px !important;
}

.phone-select-button .selected-flag {
  padding: 0 !important;
}

/* Form Control Styles */
.button-row .form-control,
.button-row .form-select {
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
}

.button-row .form-control:focus,
.button-row .form-select:focus {
  box-shadow: none;
  border-color: #3FAEFD;
}
.section_block {
  position: relative;
}

.phone-input-container {
  position: relative;
}
/* Close Button Style */
.btn-close {
  padding: 0.25rem;
  font-size: 0.875rem;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.btn-close:hover {
  opacity: 1;
}

/* Add Button Style */
.btn-primary {
  background-color: #3FAEFD;
  border-color: #3FAEFD;
  box-shadow: none;
}

.btn-primary:hover {
  background-color: #2b9fe8;
  border-color: #2b9fe8;
}
.phone-input-container {
  position: relative;
}

.phone-input-dropdown {
  position: absolute;
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}


.phone-input-container {
  position: relative;
}

.phone-input-dropdown {
  position: absolute;
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}
.react-tel-input {
  width: 100% !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  background: #fff !important;
  border: none !important;
  border-radius: 10px !important;
  height: 45px !important;
  padding-left: 65px !important;
  font-family: "Gilroy-Medium" !important;
}
.react-tel-input .flag-dropdown {
  background: transparent !important;
  border: none !important;
  pointer-events: none !important;  /* Disable dropdown */
}


.react-tel-input .country-list {
  z-index: 3;
  position: absolute;
  background: white;
}

/* Override any conflicting styles */
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
  cursor: pointer;
}

.react-tel-input .flag-dropdown.open {
  z-index: 4;
}
.react-tel-input .selected-flag {
  background: transparent !important;
  width: 65px !important;
  padding: 0 0 0 15px !important;
}

.react-tel-input .country-list {
  margin: 10px 0 10px -1px !important;
  width: 300px !important;
  max-height: 200px !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15) !important;
  border: 1px solid #e0e0e0 !important;
  z-index: 9999 !important;
}


.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}

.react-tel-input .country-list .country {
  padding: 10px !important;
  font-family: "Gilroy-Medium" !important;
}
.react-tel-input .country-list .country:hover {
  background-color: #f5f5f5 !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #f5f5f5 !important;
}
.react-tel-input .country-list .search {
  padding: 10px !important;
  margin-bottom: 5px !important;
}

.react-tel-input .country-list .search-box {
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 5px 10px !important;
  margin-left: 24px !important;
}

.section_block:focus-within {
  z-index: 9999 !important;
}
.auto-section-1 {
  padding: 16px 24px;
  background: transparent;
  border: none;
  box-shadow: none;
}

.auto-section-1 .d-flex {
  padding: 0;
}

.auto-section-1-sub-1 {
  display: flex;
  align-items: center;
  gap: 16px;
}

.auto-section-1-sub-1 button {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background: #F3F4F6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto_btn {
  background: #3B4999;
  color: white;
  border-radius: 8px;
  padding: 8px 24px;
}

/* Template name section */
.create_template .form-control {
  background-color: #fff;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  height: 44px;
  padding: 0 16px;
  font-size: 14px;
  color: #111827;
}

/* Dropdowns */
.create_template .form-select {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  height: 44px;
  padding: 0 16px;
  background-color: white;
  font-size: 14px;
  color: #111827;
  cursor: pointer;
  width: 100%;
}

/* Labels */
.create_template label {
  font-size: 14px !important;
  color: #111827;
  margin-bottom: 8px;
  font-weight: 500;
}

.create_template .form-label p {
  font-size: 14px;
  margin: 0;
}

/* Description text */
.create_template [class*="des"] {
  font-size: 12px;
  color: #6B7280;
  margin-top: 4px;
}

/* Radio button section */
.radioInputs {
  display: flex;
  gap: 16px;
  margin: 16px 0;
}

.radioInputs label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.section_block {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 16px;
}

.section_block .section_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

/* Required asterisk */
.text-danger {
  color: #DC2626;
  margin-left: 2px;
}

/* Info icon */
.ri-information-fill {
  color: #9CA3AF;
  font-size: 16px;
  margin-left: 8px;
  cursor: help;
}

/* Body textarea */
textarea.form-control {
  min-height: 120px;
  padding: 12px 16px;
  font-size: 14px;
  resize: vertical;
}
