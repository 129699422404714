.blockArea {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  margin-bottom: 20px;
}
.blockArea:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
/* Update existing date field styles */
.blockArea .date-time-field {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.blockArea .date-time-field input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-family: "Gilroy-Medium";
}

.blockArea .date-time-field::after {
  content: "\ea77"; /* Calendar icon from Remixicon */
  font-family: "remixicon";
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
  font-size: 20px;
}

.blockArea .date-time-field:hover input {
  border-color: #3FAEFD;
}
.blockArea .date-time-field i.calendar-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #666;
}
.blockArea img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.cancel {
  font-family: "Gilroy-Bold";
  font-weight: 700;
  color: #ff4747d1;
  font-size: 18px;
}

.blockArea:not(:first-of-type) {
  margin-top: 10px;
}

.blockArea input,
.blockArea select {
  border: 1px solid #00000080;
  border-radius: 10px;
  padding: 10px;
  height: 46px;
  font-family: "Gilroy-Bold";
  font-size: 14px;
  font-weight: 400;
  color: #000;
  width: 100%;
}

.blockArea input:focus,
.blockArea select:focus {
  outline: none;
}

.timeBlock {
  padding: 30px;
}

.timeBlock p {
  font-family: "Gilroy-Bold";
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin-bottom: 15px;
}

.timeBlock label {
  display: block;
  font-family: "Gilroy-Bold";
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin-bottom: 5px;
}

.timeBlock input {
  border: 0;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  height: 46px;
  width: 100%;
}

.timeBlock input:focus {
  outline: 0;
}

.btn_blue {
  letter-spacing: 1px;
  border-radius: 10px;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  background: #3a4b86;
}

.send_btn {
  background: #4cb050;
  color: #ffff;
  width: 150px;
  letter-spacing: 1px;
  border-radius: 10px;
  font-family: "Gilroy-Bold";
  font-size: 18px;
}

.filterArea {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.filterArea label {
  font-family: "Gilroy-Bold";
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.filterArea .search {
  position: relative;
  flex: 1;
  margin-left: 10px;
}

.filterArea .search i {
  left: 7px;
}

.filterArea .search input {
  width: 100%;
  background: #ebf0f4;
  font-family: "Gilroy-Bold";
  color: #000;
  border: 0;
  border-radius: 10px;
  height: 40px;
  padding: 10px;
  padding-left: 30px;
}

.filterArea select {
  flex: 1;
  margin-left: 10px;
  background: #ebf0f4;
  font-family: "Gilroy-Bold";
  color: #000;
  border: 0;
  border-radius: 10px;
  height: 40px;
  padding: 10px;
}

.filterArea select:focus {
  outline: none;
}

.filterArea .search input:focus,
.filterArea .search select:focus {
  outline: none;
}

.filterArea button {
  background: #ebf0f4;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  border: 0;
  margin-left: 10px;
}

.createWhatsapp-broadcast {
  background: #ebf0f4;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.receipientListBox {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 380px;
  max-height: 380px;
}



.checkbox label {
  position: relative;
  font-family: "Gilroy-Bold";
  color: #00000080;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.checkbox label input {
  position: absolute;
  opacity: 0;
  font-size: 15px;
}

.checkbox label span {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  margin-right: 10px;
  border: 2px solid #000;
}

.checkbox label span:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 5px;
  top: 3px;
  left: 2px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  display: none;
}


.checkbox label input:checked + span {
  background: #000;
}

.checkbox label input:checked + span:before {
  display: block;
}

.leadLists {
  padding: 15px 0;
  flex: 1;
  overflow-y: auto;
}

.leadLists ul {
  list-style: none;
  padding-left: 0;
}


.leadLists ul li:not(:first-child) {
  padding-top: 15px;
}

.leadLists ul li .checkbox label {
  font-family: "Gilroy-Medium";
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.back {
  background: #3faefdd1 !important;
}

.fakeDropdown {
  border: 1px solid #a2999980;
  border-radius: 10px;
  padding: 10px;
  height: 46px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  transition: all 0.2s ease;
}
.fakeDropdown:hover {
  border-color: #3FAEFD;
}
.fakeDropdown label {
  font-family: "Gilroy-Bold";
  font-size: 14px;
  font-weight: 400;
  color: #000;
  white-space: normal; /* Allow wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word; /* Break long words */
}

.fakeDropdown img {
  margin-right: 0;
}
.template-selector-container {
  background: #ebf0f4;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.template-selector-header {
  font-family: "Gilroy-Bold";
  font-weight: 400;
  font-size: 18px;
  color: #000;
}

.template-selector-body {
  background: #fff;
  border-radius: 10px;
  padding: 24px 15px;
}

.template-selector-dropdown {
  border: 1px solid #00000080;
  border-radius: 10px;
  padding: 10px;
  height: 46px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Template Selector Styles */
.template-selector {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f5f7f9;
  padding: 20px;
}


.template-selector-content {
  padding: 20px;
}

/* Search Section */
.search-section {
  margin-bottom: 20px;
}

.search {
  position: relative;
}

.search i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.search input {
  width: 100%;
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
}

.search input:focus {
  outline: none;
  border-color: #3FAEFD;
}

/* Header */
.template-header {
  margin-bottom: 20px;
}

.header-title {
  font-family: "Gilroy-Bold";
  font-size: 18px;
  color: #000;
  margin: 0;
}

.loading-dots {
  margin-left: 5px;
  color: #666;
}

/* Templates Grid */
.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 0 20px;
  overflow-y: auto;
  flex: 1;
}
.template-search {
  position: relative;
  margin-bottom: 20px;
  padding: 0 20px;
}

.template-search input {
  width: 100%;
  padding: 12px 40px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
}

.template-search i {
  position: absolute;
  left: 32px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

/* Template Card */
.template-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 16px;
}



.template-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.template-card.selected {
  border: 5px solid rgb(255, 255, 255) !important;
  background: rgb(191, 197, 230) !important;
  box-shadow: 0 2px 8px rgba(63, 174, 253, 0.15);
}
.template-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

/* Status Badges */
.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  margin-right: 8px;
}

.status-approved {
  background: #4CB050;
  color: white;
}

.status-pending {
  background: #FFA500;
  color: white;
}

.status-Failed {
  background: #FF4747;
  color: white;
}

.language-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  background: #F5F5F5;
  color: #666;
}

.template-name {
  font-family: "Gilroy-Bold";
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
  word-break: break-word;
}

.template-category {
  font-size: 14px;
  color: #666;
  margin: 0 0 8px 0;
}

.template-message {
  font-size: 13px;
  color: #666;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #eee;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 60px;
}

.template-footer {
  font-size: 12px;
  color: #888;
  margin-top: 8px;
}

/* Error Message */
.error-message {
  text-align: center;
  padding: 20px;
  color: #ff4747;
  background: #fff;
  border-radius: 8px;
  margin: 20px 0;
}

.error-message button {
  margin-left: 10px;
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 40px;
  color: #666;
  background: white;
  border-radius: 8px;
}

/* Pagination */
.pagination {
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
}
.template-actions .discard-btn {
  color: #666;
  background: none;
}

.template-actions .save-btn {
  background: #3FAEFD;
  color: white;
}
.page-info {
  font-size: 14px;
  color: #666;
}
.template-actions button {
  padding: 8px 24px;
  border-radius: 6px;
  font-weight: 500;
}

.template-actions {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 16px 20px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.status-badge.approved {
  background: #4CB050;
  color: white;
}

.status-badge.rejected {
  background: #FF4747;
  color: white;
}
.template-selector {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f5f7f9;
  padding: 15px; /* Balanced padding */
}

.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
  gap: 16px; 
  padding: 16px;
}





.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}
  
.template-content {
  margin-top: 12px;
}

.template-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.template-message {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.template-item {
  min-height: 200px;
  display: flex;
  flex-direction: column;
}









/* Button Enhancements */
.btn.auto_btn {
  background: #3FAEFD;
  color: white;
  padding: 10px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.btn.auto_btn:hover {
  background: #2b9be9;
  transform: translateY(-1px);
}


.form-control {
  height: 48px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.form-control:focus {
  border-color: #3FAEFD;
  box-shadow: 0 0 0 2px rgba(63, 174, 253, 0.1);
}

.btn-primary {
  background-color: #3FAEFD;
  border-color: #3FAEFD;
  color: white;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.btn-primary:hover {
  background-color: #2b9be9;
  transform: translateY(-1px);
}

.btn-outline-primary {
  border-color: #3FAEFD;
  color: #3FAEFD;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.btn-outline-primary:hover {
  background-color: rgba(63, 174, 253, 0.1);
}

/* Add to createWhatsappBroadcast.css */


.createWhatsapp-broadcast {
  position: relative;
  min-height: calc(100vh - 64px);
  padding-bottom: 70px; /* Match bottom nav height */
  display: flex;
  flex-direction: column;
}

/* Add to existing CSS */
.campaign-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
  font-family: "Gilroy-Medium";
}

.campaign-summary-label {
  color: #666;
}

.campaign-summary-value {
  color: #000;
  font-family: "Gilroy-Bold";
  text-align: right;
}
/* Progress container */


/* Background dotted line - lowest layer */
.progress-track {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 2px;
  z-index: 0;
  margin: 0px 5%;
  background-image: repeating-linear-gradient(
    to right,
    #D1D5DB 0,
    #D1D5DB 4px,
    transparent 4px,
    transparent 8px
  );
}

/* Filled progress line - middle layer */
.progress-fill {
  position: absolute;
  top: 35%;
  left: 0;
  transform: translateY(-50%);
  margin-left: 5%;
  height: 2px;
  background: #3FAEFD;
  transition: width 0.3s ease;
  z-index: 2;
}

/* Steps container - top layer */
.progress-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 10%;
  z-index: 10;
}

/* Individual step container */
.progress-step-container {
  position: relative;
  z-index: 10;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Step circle */
.progress-step-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: white;
  border: 2px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  position: relative;
  z-index: 15;
}

.progress-step-circle.active {
  border-color: #3FAEFD;
  background: #3FAEFD;
  color: white;
}

.progress-step-circle.completed {
  background: #4CB050;
  border-color: #4CB050;
  color: white;
}

/* Step label */
.progress-step-label {
  font-size: 14px;
  color: #6b7280;
  text-align: center;
  min-width: 80px;
  position: relative;
  z-index: 15;
}

.progress-step-label.active {
  color: #3FAEFD;
}

/* Add to existing createWhatsappBroadcast.css */
.progress-area {
  margin-bottom: 20px;
}

.progress-steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.progress-step {
  flex: 1;
  display: flex;
  align-items: center;
}

.progress-number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.progress-number.active {
  border-color: #3FAEFD;
  background: #3FAEFD;
  color: white;
}

.progress-number.completed {
  border-color: #4CB050;
  background: #4CB050;
  color: white;
}

.progress-text {
  margin-left: 8px;
  font-size: 14px;
  color: #666;
  flex-shrink: 0;
}

.progress-text.active {
  color: #3FAEFD;
}

.progress-line {
  flex: 1;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.line-inner {
  height: 2px;
  background: #e5e7eb;
  width: 100%;
  transition: background-color 0.2s ease;
}

.line-inner.active {
  background: #3FAEFD;
}

.line-inner.completed {
  background: #4CB050;
}

/* Maintain existing font classes */
.font-gilroy-bold {
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}

.font-gilroy-medium {
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 500;
}
/* Fixed Navigation Buttons */
/* Fixed Navigation Buttons */
.navigation-buttons {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px); /* To provide some padding on sides */
  max-width: 600px; /* Optional: Limit the width */
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  background: rgba(255, 255, 255, 0.95); /* Slight transparency */
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000; /* Ensure it stays on top */
}

.navigation-buttons .btn {
  flex: 1;
  margin: 0 5px;
}

@media (max-width: 768px) {
  .navigation-buttons {
    width: calc(100% - 20px);
  }
}
