.integrations-container {
  margin-left: 240px;
  padding: 24px;
  padding-top: 18px;
  background: #f8f9fb;
}

.integrations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.integration-card {
  background: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.integration-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.integration-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.integration-icon {
  width: 24px;
  height: 24px;
}

.integration-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.integration-desc {
  color: #666;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 1.5;
}

.connect-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background: #3faefd;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.connect-button.manage {
  background: #f5f5f5;
  color: #333;
}

.check-icon {
  color: #22c55e;
  font-size: 16px;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 600px;
  position: relative;
}
.page-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #333;
}
