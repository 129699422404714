.followups-section-1 {
  .table {
    thead {
      background: #fff;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 0.75rem;
    }
    tbody {
      tr {
        background: #fff;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
          0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0.75rem;
        cursor: pointer;
        td {
          .btn {
            padding: 0 7px;
            font-size: 19px;
            line-height: 20px;
            vertical-align: text-bottom;
            color: #3faefd;
            &:focus,
            &:hover {
              background-color: #3faefd;
            }
          }
          i {
            font-size: 19px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.follwup_drower {
  .form-container {
    .form-group {
      padding-bottom: 15px;
      .form-control {
        font-family: "Inter", sans-serif;
        font-weight: 400;
      }
    }
  }
}

.bulk_container {
  flex-direction: column;
  display: flex;
  padding: 0px 10px;

  label {
    font-weight: 600;
    font-size: 14px;
  }

  .bulk_button_container {
    display: flex;
    border: 1px solid #3da6f6;
    width: fit-content;
    border-radius: 4px;
    overflow: hidden;
    margin: 5px 0px 10px 0;
    height: 40px;

    button {
      background-color: transparent;
      padding: 0 10px;
      border-right: 1.2px solid #3da6f6;
      min-width: 100px;

      &:last-child {
        border-right: 0px;
      }
    }
  }
}
