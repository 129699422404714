.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rs-drawer-body {
  background-color: #ebf0f4 !important;
}

.label {
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
  background-color: #4cb050;
  color: #fff;
}
.bg-lighter {
  background-color: rgba(235, 240, 244, 1) !important;
}
.rounded-8 {
  border-radius: 8px !important;
}
.rounded-10 {
  border-radius: 10px !important;
}
.broadcastBorder {
  border-right: 1px dashed #1a1a1ac9;
  min-height: calc(100vh - 130px);
}
