.task_left_icon_container {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #3faefd;
  color: #fff;
  display: flex;
  justify-items: center;
  align-items: center;
}

.task_left_icon_container > * {
  margin: auto;
}

.task_info_container {
  background-color: #ebf0f4;
  padding: 8px 10px;
  border-radius: 10px;
}

.task_bottom_time {
  display: block;
  text-align: end;
}

.task_left_container {
  width: 12%;
}

.task_heading_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task_heading_wrapper > h5 {
  max-width: 80%;
}

.progress-step {
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
  position: relative;
}

.progress-step::after {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  left: 6.8%; /* relative to marker width */
  top: 35px; /* offset top */
  height: 100%; /* responsive heights */
  margin: auto;
  border-left: 1px dashed #0000004d;
  z-index: 1; /* handle stacking context */
}

.progress-step:last-child::after,
.progress-step--no-step::after {
  /* handle the last exception, not every instance */
  display: none;
}

@media screen and (max-width: 600px) {
  .task_left_container {
    width: 20%;
  }
}

.task_notes_left_icon_container {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: #3faefd;
  color: #fff;
  display: flex;
  justify-items: center;
  align-items: center;
}

.task_notes_left_icon_container span {
  font-size: 10px !important;
  text-align: center;
  margin: auto;
}

.mark_checkbox {
  margin-top: 15px;
  font-size: 15px;
}
