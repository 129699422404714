.lead_selector {
  position: relative;

  .lead_heaader {
    display: flex;
    margin-bottom: 10px;
    height: 35px;

    .lead_search {
      background-color: #ebf0f4;
      border-radius: 6px;
      padding: 0px 10px;
      width: 250px;
      margin-left: auto;
    }

    .selected_count {
      margin-left: 10px;
      color: #000;
      font-family: "Gilroy-Bold";
      font-size: 13px;
    }

    .close_button {
      margin-left: 10px;
      background-color: #ebf0f4;
      color: #000;
      font-family: "Gilroy-Bold";
      font-size: 12px;
      width: 100px;
      border-radius: 5px;
    }
  }
}
