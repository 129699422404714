.cardList {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.cardList input {
  position: absolute;
  opacity: 0;
}

.cardList input:checked + span {
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0px 0px 0px 1px #ccc;
  border-radius: 10px;
}

.cardList .cardHead {
  display: flex;
  justify-content: space-between;
  font-family: "Gilroy-Bold";
  font-size: 20px;
  font-weight: 700;
  color: #000;
  width: 100%;
}

.cardList .cardHead button {
  background: #4cb050;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 15px;
}

.cardList .type {
  width: 100%;
  font-family: "Gilroy-Bold";
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
}

.templateBody {
  background: #ebf0f4;
  border-radius: 10px;
  min-height: 260px;
  width: 100%;
  font-family: "Gilroy-Bold";
  font-size: 20px;
  color: #000;
  padding: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardfooter {
  font-family: "Gilroy-Bold";
  font-size: 12px;
  color: #000;
  font-weight: 700;
  display: flex;
  width: 100%;
  margin-top: 30px;
}
.cardfooter p {
  margin-left: auto;
}

.selected_template {
  background-color: #bad8f0 !important;
}
