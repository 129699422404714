.ultimate_container {
  display: flex;
  min-height: 90vh;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f8f9fa;
}

.ultimate_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 440px;
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;

  &.hidden {
    transform: translateY(-16px);
    opacity: 0;
  }
}

.ultimate_icon {
  margin-bottom: 2rem;
  color: #3FAEFD;
  
  .alert_icon {
    animation: pulse 2s infinite;
    height: 56px;
    width: 56px;
  }
}

.ultimate_title {
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 28px;
  color: #1a202c;
  margin-bottom: 1.25rem;
  letter-spacing: -0.5px;
}

.ultimate_description {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  color: #4a5568;
  margin-bottom: 2.5rem;
  line-height: 1.6;
  max-width: 360px;
}

.ultimate_actions {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
}

.ultimate_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.875rem 1.75rem;
  background-color: #3FAEFD;
  color: white;
  border-radius: 10px;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s ease;
  border: 2px solid transparent;

  &:hover {
    background-color: darken(#3FAEFD, 5%);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(63, 174, 253, 0.25);
  }

  &:active {
    transform: translateY(0);
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.ultimate_link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #4a5568;
  font-family: "Gilroy", sans-serif;
  font-size: 15px;
  text-decoration: none;
  transition: all 0.2s ease;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #3FAEFD;
    text-decoration: underline;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

// Media queries for responsiveness
@media (max-width: 640px) {
  .ultimate_container {
    padding: 1rem;
  }

  .ultimate_content {
    padding: 2rem;
    max-width: 100%;
  }

  .ultimate_title {
    font-size: 24px;
  }

  .ultimate_description {
    font-size: 15px;
    margin-bottom: 2rem;
  }

  .ultimate_button {
    padding: 0.75rem 1.5rem;
    font-size: 15px;
  }
}