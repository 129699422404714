.main {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  min-height: 120vh;
  background-color: #f8f9fa;
}

.card {
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.form-control {
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  padding: 0.625rem 1rem;
  transition: all 0.2s ease;
}

.form-control:focus {
  border-color: #0095f6;
  box-shadow: 0 0 0 2px rgba(0, 149, 246, 0.1);
}

.btn-primary {
  background-color: #0095f6;
  border-color: #0095f6;
  border-radius: 8px;
  padding: 0.625rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary:hover {
  background-color: #0086e0;
  border-color: #0086e0;
  transform: translateY(-1px);
}

.status-indicator {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-indicator.success {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.status-indicator.warning {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.status-indicator.danger {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.profile-picture-container {
  position: relative;
  display: inline-block;
}

.profile-picture-upload {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background: #0095f6;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid #fff;
  color: #fff;
}

.profile-picture-upload:hover {
  background: #0086e0;
  transform: scale(1.05);
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
}

.health-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.health-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.375rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .card-body {
    padding: 1.25rem;
  }
  
  .col-md-4 {
    margin-bottom: 1.5rem;
  }

  .btn-primary {
    width: 100%;
    margin-top: 1rem;
  }
}

.phone-register-section {
  padding: 1rem;
  background-color: #f8fafc;
  border-radius: 8px;
  margin-top: 1rem;
}

.verification-steps {
  margin-top: 1rem;
  padding-left: 1.5rem;
}

.verification-steps li {
  margin-bottom: 0.5rem;
  color: #4b5563;
}